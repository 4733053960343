import { mapActions, mapGetters } from 'vuex';
import i18n from '../i18n';

export default {
  name: 'OrderNumber',
  props: ['phones'],
  i18n,
  computed: {
    ...mapGetters({
      isDesktop: 'shared/device/isDesktop',
      callTrackingDefaultInfo: 'cabinet/advertisements/manage/callTrackingDefaultInfo',
    }),
  },
  data() {
    return {
      loading: false,
      selectedPhoneId: '',
    };
  },
  methods: {
    ...mapActions({
      createServiceOrder: 'others/createServiceOrder',
    }),
    createOrder() {
      this.loading = true;
      const payload = {
        target: 'callTracking',
        params: { phoneId: this.selectedPhoneId },
      };

      if (this.isDesktop) {
        const billingWindow = window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank', 'fullscreen=no,height=768,width=1024,toolbar=no,scrollbars=yes');
        this.createServiceOrder(payload)
          .then(({ orderLink }) => {
            billingWindow.location.href = orderLink;
          }).catch((error) => {
            console.error(`Refill order error -->${JSON.stringify(error)}`);
            billingWindow.close();
          });
      } else {
        this.createServiceOrder(payload)
          .then(({ orderLink }) => {
            window.location = orderLink;
          }).catch((error) => {
            console.error(`Refill order error -->${JSON.stringify(error)}`);
          });
      }

      this.$emit('changeStep');
      this.loading = false;
    },
  },
  mounted() {
    if (this.phones.length) this.selectedPhoneId = this.phones[0].id;
  },
};
