import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
	metaInfo() {
		return {
			bodyAttrs: {
				class: !this.isClose && this.isMobile ? 'modal-open' : ''
			}
		};
	},
	name: 'Filter_',
	data() {
		return {
			isClose: true,
		};
	},
	components: {
		Category: () => import('./Category/index.vue'),
		BrandModel: () => import('./Series/index.vue'),
		Price: () => import('./Price/index.vue'),
		Top: () => import('./Top/index.vue'),
		PublicationDate: () => import('./PublicationDate/index.vue'),
		ExpireDate: () => import('./ExpireDate/index.vue'),
		Sort: () => import('./Sort/index.vue')
	},
	computed: {
		...mapGetters({
			segment: 'cabinet/segment',
			filterData: 'cabinet/advertisements/filter/filterData',
			getCategories: 'cabinet/advertisements/filter/getCategories',
			langId: 'lang/id',
		}),
	},
	methods: {
		...mapMutations({
			reset: 'cabinet/advertisements/filter/reset'
		}),
		...mapActions({
			fetchFilterParam: 'cabinet/advertisements/filter/fetchFilterParam',
			filterBrandByCategory: 'cabinet/advertisements/filter/filterBrandByCategory'
		}),
		toggle() {
			this.isClose = !this.isClose;
		},
		search() {
			this.toggle();
			this.$emit('search');
		},
		filterReset() {
			this.reset();
			this.$root.$emit('resetFilter');
		},
	},
	mounted() {
		const currentPage = this.$route.meta.type;
		const langId = this.langId;
		this.fetchFilterParam({currentPage, langId});
	},
	i18n: {
		messages: {
			uk: {
				Фильтр: 'Фільтр',
				Поиск: 'Пошук',
				'сбросить все фильтры': 'скинути всі фільтри'
			},
			ru: {
				Фильтр: 'Фильтр',
				Поиск: 'Поиск',
				'сбросить все фильтры': 'сбросить все фильтры'
			}
		}
	}
};
