import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Price',
  computed: {
    ...mapGetters({
      price: 'cabinet/advertisements/filter/price',
      currencyId: 'cabinet/advertisements/filter/currency'
    }),
    gte: {
      set(value) {
        value = Number(value);
        let price = this.price;
        if (value) {
          price.gte = value;
        } else {
          delete price.gte;
        }
        this.setPrice(price);
      },
      get() {
        return this.price.gte;
      }
    },
    lte: {
      set(value) {
        value = Number(value);
        let price = this.price;
        if (value) {
          price.lte = value;
        } else {
          delete price.lte;
        }
        this.setPrice(price);
      },
      get() {
        return this.price.lte;
      }
    },
    currency: {
      set(value) {
        this.setCurrency(Number(value));
      },
      get() {
        return this.currencyId;
      }
    }
  },
  methods: {
    ...mapActions({
      setPrice: 'cabinet/advertisements/filter/price',
      setCurrency: 'cabinet/advertisements/filter/currency'
    })
  },
  i18n: {
    messages: {
      uk: {
        Цена: 'Ціна',
        От: 'Від'
      },
      ru: {
        Цена: 'Цена',
        От: 'От'
      }
    }
  }
};
