export default {
  messages: {
    uk: {
      Применить: 'Застосувати',
      Выделить: 'Виділити',
      все: 'всі',
      'Выберите действие': 'Виберіть дію',
      Поднять: 'Підняти',
      'Повторно опубликовать': 'Повторно опублікувати',
      'Сообщить о продаже': 'Повідомити про продаж',
      Опубликовать: 'Опублікувати',
      'Удалить в архив': 'Видалити в архів',
      Восстановить: 'Відновити',
      'Удалить с сайта': 'Видалити з сайту',
      'Сначала выберете объявление': 'Спершу оберіть оголошення',
      'Налаштувати автом. перепублікацію': 'Налаштувати автом. перепублікацію',
    },
    ru: {
      Применить: 'Применить',
      Выделить: 'Выделить',
      все: 'все',
      'Выберите действие': 'Выберите действие',
      Поднять: 'Поднять',
      'Повторно опубликовать': 'Повторно опубликовать',
      'Сообщить о продаже': 'Сообщить о продаже',
      Опубликовать: 'Опубликовать',
      'Удалить в архив': 'Удалить в архив',
      Восстановить: 'Восстановить',
      'Удалить с сайта': 'Удалить с сайта',
      'Сначала выберете объявление': 'Сначала выберете объявление',
      'Налаштувати автом. перепублікацію': 'Настроить автом. перепубликацию',
    }
  }
}
