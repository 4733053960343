import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Top',
  computed: {
    ...mapGetters({
      getTop: 'cabinet/advertisements/filter/top'
    }),
    selected: {
      get() {
        return this.getTop;
      },
      set(value) {
        this.setTop(value);
      }
    }
  },
  methods: {
    ...mapActions({
      setTop: 'cabinet/advertisements/filter/top'
    })
  },
  i18n: {
    messages: {
      uk: {
        'объявления': 'оголошення',
        'Наличие ТОП': 'Наявність ТОП',
        'Все объявления': 'Всі оголошення',
        'Только с уровнем ТОП': 'Тільки з рівнем ТОП',
        'Только без уровня ТОП': 'Тільки без рівня ТОП',
      },
      ru: {
        'объявления': 'объявления',
        'Наличие ТОП': 'Наличие ТОП',
        'Все объявления': 'Все объявления',
        'Только с уровнем ТОП': 'Только с уровнем ТОП',
        'Только без уровня ТОП': 'Только без уровня ТОП',
      }
    }
  }
};
