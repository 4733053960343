export default {
  messages: {
    uk: {
      'Контакти, які зараз відображаються на сторінках ваших авто': 'Контакти, які зараз відображаються на сторінках ваших авто',
      'Додати або замінити номер': 'Додати або замінити номер',
      'Тимчасовий номер': 'Тимчасовий номер',
      'Відображається замість ваших контактів': 'Відображається замість ваших контактів. Це допоможе уникнути спаму в месенджерах та небажаних дзвінків після продажу авто, а також зменшить їх кількість у процесі продажу',
      Вартість: 'Вартість',
      Оплатити: 'Оплатити',
      'Замовити тимчасовий номер': 'Замовити тимчасовий номер',
      'Показувати підмінний номер на авто': 'Показувати підмінний номер на авто',
      'дійсний ще': 'дійсний ще {days} днів',
      'Управління номерами': 'Управління номерами',
      'На сторінках авто відображаються ваші особисті контакти': 'На сторінках авто відображаються ваші особисті контакти',
      'Ваші контакти приховані, а замість них': 'Ваші контакти приховані, а замість  них на сторінках авто відображається тимчасовий номер. Всі дзвінки переадресовуються на обраний особистий номер',
      'Покупці бачать підмінний номер': 'Покупці бачать підмінний номер, а всі дзвінки переадресовуються на ваш особистий. Послуга діє {period} днів, протягом яких ви можете вимикати переадресацію і відображення тимчасового номеру в будь-який момент. Тоді, як і після закінчення дії послуги, на сторінках авто знову будуть ваші контакти.',
      'Оберіть номер, на який будуть переадресовуватись дзвінки покупців': 'Оберіть номер, на який будуть переадресовуватись дзвінки покупців',
      'Після успішної оплати послуга управілння буде активована протягом 10хв': 'Після успішної оплати послуга управілння буде активована протягом 10 хв.',
    },
    ru: {
      'Контакти, які зараз відображаються на сторінках ваших авто': 'Контакты, которые сейчас отображаются на страницах вашего авто',
      'Додати або замінити номер': 'Добавить или заменить номер',
      'Тимчасовий номер': 'Временный номер',
      'Відображається замість ваших контактів': 'Отображается вместо контактов. Это поможет избежать спама в мессенджерах и нежелательных звонков после продажи авто, а также уменьшит их количество в процессе продажи.',
      Вартість: 'Стоимость',
      Оплатити: 'Оплатит',
      'Замовити тимчасовий номер': 'Заказать временный номер',
      'Показувати підмінний номер на авто': 'Показывать подменный номер на авто',
      'дійсний ще': 'дійсний ще {days} днів',
      'Управління номерами': 'Управление номерами',
      'На сторінках авто відображаються ваші особисті контакти': 'На страницах авто отображаются ваши контакты',
      'Ваші контакти приховані, а замість них': 'Ваши контакты скрыты, а вместо них на страницах авто отображается временный номер. Все звонки переадресовываются на выбранный личный номер',
      'Покупці бачать підмінний номер': 'Покупатели видят подменный номер, а все звонки переадресовываются на ваш личный. Услуга действует {period} дней, в течение которых вы можете отключать переадресацию и отображение временного номера в любой момент. В то время, как и после окончания действия услуги, на страницах авто снова будут ваши контакты.',
      'Оберіть номер, на який будуть переадресовуватись дзвінки покупців': 'Выберите номер, на который будут переадресовываться звонки покупателей',
      'Після успішної оплати послуга управілння буде активована протягом 10хв': 'После успешной оплаты услуга управления будет активирована в течение 10 мин.',
    },
  },
};
