import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PublicationDate',
  computed: {
    ...mapGetters({
      getPublication: 'cabinet/advertisements/filter/publication'
    }),
    gte: {
      get() {
        return this.getPublication.gte;
      },
      set(value) {
        let publication = Object.assign({}, this.getPublication);
        if (value) {
          publication.gte = value;
        } else {
          delete publication.gte;
        }
        this.setPublication(publication);
      }
    },
    lte: {
      get() {
        return this.getPublication.lte;
      },
      set(value) {
        let publication = Object.assign({}, this.getPublication);
        if (value) {
          publication.lte = value;
        } else {
          delete publication.lte;
        }
        this.setPublication(publication);
      }
    }
  },
  methods: {
    ...mapActions({
      setPublication: 'cabinet/advertisements/filter/publication'
    })
  },
  i18n: {
    messages: {
      uk: {
        публикации: 'публікації',
        От: 'Від'
      },
      ru: {
        публикации: 'публикации',
        От: 'От'
      }
    }
  }
};
