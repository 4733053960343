import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'ThanksForVINPopup',
	components: {
		Popup: () => import('../../../Common/Popup.vue'),
	},
	computed: {
		...mapGetters({
			info_: 'cabinet/advertisements/advertisement/info',
			popup: 'cabinet/advertisements/popups/popup',
		}),
		autoTitle() {
			const id = this.popup.id;
			const info = this.info_(id);
			return info ? `${info.title} (${id})` : 'Ваше авто';
		},
	},
	methods: {
		...mapActions({
			close: 'cabinet/advertisements/popups/close',
		}),
	},
	i18n: {
		messages: {
			ru: {
				'Поздравляем, ваше авто с VIN-кодом': 'Поздравляем, ваше авто с VIN-кодом',
				'поднялось в поиске к предложениям с VIN-кодом и теперь вы можете продать его быстрее': 'поднялось в поиске к предложениям с VIN-кодом и теперь вы можете продать его быстрее',
				'Хорошо': 'Хорошо',
			},
			uk: {
				'Поздравляем, ваше авто с VIN-кодом': 'Вітаємо, ваше авто з VIN-кодом',
				'поднялось в поиске к предложениям с VIN-кодом и теперь вы можете продать его быстрее': 'піднялось у пошуку до пропозицій з VIN-кодом і тепер ви зможете продати його швидше',
				'Хорошо': 'Добре',
			}
		}
	}
};
