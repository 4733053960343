import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'Category',
	computed: {
		...mapGetters({
			getCategories: 'cabinet/advertisements/filter/getCategories',
			getCurrentCategory: 'cabinet/advertisements/filter/getCurrentCategory',
		}),
		options() {
			if (this.getCategories) {
				return Object.values(this.getCategories);
			}
		},
		selected: {
			get: function () {
				return this.getCurrentCategory || 0;
			},
			set: function (value) {
				this.filterBrandByCategory(value);
				this.setCategory(value);
			}
		}
	},
	methods: {
		...mapActions({
			setCategory: 'cabinet/advertisements/filter/category',
			filterBrandByCategory: 'cabinet/advertisements/filter/filterBrandByCategory'
		})
	},
	i18n: {
		messages: {
			uk: {
				'Тип транспорта': 'Тип транспорту',
				Выберите: 'Виберіть'
			},
			ru: {
				'Тип транспорта': 'Тип транспорта',
				Выберите: 'Выберите'
			}
		}
	}
};
