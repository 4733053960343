import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'SalePopup',
  components: {
    Popup: () => import('../../../Common/Popup.vue'),
  },
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
      popup: 'cabinet/advertisements/popups/popup',
      type: 'cabinet/advertisements/type',
      langId: 'lang/id',
      userInfo: 'cabinet/userInfo/userData'
    }),
    info() {
      return this.info_(this.id);
    },
    id() {
      return this.popup.id;
    },
    ids() {
      return this.popup.ids;
    }
  },
  methods: {
    ...mapActions({
      close: 'cabinet/advertisements/popups/close',
      moveToArchive_: 'cabinet/advertisements/moveToArchive'
    }),
    ...mapMutations({
      incrementArchiveAdvCnt: 'cabinet/leftBar/incrementArchiveAdvCnt',
      decrementAdvCntByName: 'cabinet/leftBar/decrementAdvCntByName'
    }),
    sendTags(reason, ids) {
      const {type, userInfo, id} = this;
      const action = {
        active: id ? 3 : 9,
        awaitForPayment: 5,
        draft: 7
      }[type];

      ids
        .map((id) => {
          this.gaEvent('Cabinet', 'clickon_button_tellsold', 'Mark_Sold');
          this._slonik({
            project_id: 1,
            event_id: 493,
            proposal_id: id,
            click_action: action,
            delete_reason: reason,
          });
        });

    },
    moveToArchive({reason}) {
      if (this.id) {
        this.sendTags(reason, [this.id]);
        this.moveToArchive_({reason, id: this.id})
            .then(() => {
              this.incrementArchiveAdvCnt();
              this.decrementAdvCntByName(this.$route.name);
              this.close();
            })
            .catch((e) => {
              this.close();
              console.log('76, Cabinet/Advertisements/SalePopup/index.js', e);
            });
      } else {
        this.sendTags(reason, this.ids);
        Promise
          .all(
            this.ids.map(id => {
            this.moveToArchive_({ reason, id })
                .then((payload) => {
                  this.incrementArchiveAdvCnt();
                  this.decrementAdvCntByName(this.$route.name);
                  return payload;
                });
            })
          )
          .then(() => {
            this.close();
          })
          .catch((e) => {
            this.close();
            console.log('98, Cabinet/Advertisements/SalePopup/index.js', e);
          });
      }

    }
  },
  i18n: {
    messages: {
      uk: {
        'с помощью': 'за допомогою',
        'другом месте': 'іншому місці',
        'Пожалуйста, укажите способ продажи Вашего автомобиля': 'Будь ласка, вкажіть спосіб продажу Вашого автомобіля',
        'Пожалуйста, укажите причину, по которой Вы удаляете объявления': 'Пожалуйста, укажите причину, по которой Вы удаляете объявления',
        'Сообщить о продаже авто': 'Повідомити про продаж авто',
        'Как удалить авто с': 'Як видалити авто з'
      },
      ru: {
        'с помощью': 'с помощью',
        'другом месте': 'другом месте',
        'Пожалуйста, укажите способ продажи Вашего автомобиля': 'Пожалуйста, укажите способ продажи Вашего автомобиля',
        'Пожалуйста, укажите причину, по которой Вы удаляете объявления': 'Будь ласка, вкажіть причину, по якій Ви видаляєте оголошення',
        'Сообщить о продаже авто': 'Сообщить о продаже авто',
        'Как удалить авто с': 'Как удалить авто с'
      }
    }
  }
};
