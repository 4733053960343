import { get, set } from '../../../../helpers/cookie';

export default {
  name: 'VinPromo',
  data() {
    return {
      showPromo: _TARGET_ === 'client' && !get('showAdvertisementVinPromo'),
    };
  },
  methods: {
    closePromoBlock(position) {
      this.gaEvent('Cabinet', position, 'Block_stimulation_vin');
      this.showPromo = false;
      return set('showAdvertisementVinPromo', 1, { 'max-age': 2147483647 });
    },
  },
  i18n: {
    messages: {
      uk: {
        'Продайте х2 раза быстрее с проверкой по реестрам МВД': 'Продайте х2 рази швидше з перевіркою за реєстрами МВС',
        'Укажите VIN-код на странице редактирования предложения и получите выписку из реестров МВД. Авто с результатами проверки получают статус "Перевірений VIN-код" и продаются в 2 раза быстрее.': 'Вкажіть VIN-код на сторінці редагування пропозиції і отримаєте виписку з реєстрів МВС. Авто з результатами перевірки отримують статус "Перевіреній VIN-код" і продаються в 2 рази швидше.',
        'Спасибо, так и сделаю!': 'Дякую, так і зроблю!',
        'Укажите VIN-код и авто поднимется в поиске': 'Вкажіть VIN-код і авто підніметься в пошуку',
        'Это безопасно: AUTO.RIA скрывает 5 из 17 символов кода. Это бесплатно: мы проверим VIN-код по реестрам МВД и поднимем ваше предложение в поиске. Это эффективно: проверенные авто получают 90% просмотров и звонков.': 'Це безпечно: AUTO.RIA приховує 5 із 17 символів коду. Це безкоштовно: ми перевіримо VIN за офіційними базами та реєстрами. Це ефективно: перевірені авто отримують 90% переглядів і дзвінків',
      },
      ru: {
        'Продайте х2 раза быстрее с проверкой по реестрам МВД': 'Продайте х2 раза быстрее с проверкой по реестрам МВД',
        'Укажите VIN-код на странице редактирования предложения и получите выписку из реестров МВД. Авто с результатами проверки получают статус "Перевірений VIN-код" и продаются в 2 раза быстрее.': 'Укажите VIN-код на странице редактирования предложения и получите выписку из реестров МВД. Авто с результатами проверки получают статус "Перевірений VIN-код" и продаются в 2 раза быстрее.',
        'Спасибо, так и сделаю!': 'Спасибо, так и сделаю!',
        'Укажите VIN-код и авто поднимется в поиске': 'Укажите VIN-код и авто поднимется в поиске',
        'Это безопасно: AUTO.RIA скрывает 5 из 17 символов кода. Это бесплатно: мы проверим VIN по официальным базам и реестрам. Это эффективно: проверенные авто получают 90% просмотров и звонков': 'Это безопасно: AUTO.RIA скрывает 5 из 17 символов кода. Это бесплатно: мы проверим VIN по официальным базам и реестрам. Это эффективно: проверенные авто получают 90% просмотров и звонков',
      },
    },
  },
};
