import { mapActions, mapGetters } from 'vuex';
import Popup from '../../../Common/Popup.vue';
import i18n from './i18n';

export default {
  name: 'CallTrackingPopup',
  i18n,
  components: {
    Popup,
    ChangeNumber: () => import('./ChangeNumber/index.vue'),
    OrderNumber: () => import('./OrderNumber/index.vue'),
    SuccessMessage: () => import('./SuccessMessage/index.vue'),
    GovernanceNumber: () => import('./GovernanceNumber/index.vue'),
  },
  data() {
    return {
      loading: true,
      callTrackingParams: {},
      currentStep: '',
      showCallTrackingBlock: false,
      SERVICE_PRICE: 200,
    };
  },
  computed: {
    ...mapGetters({
      popup: 'cabinet/advertisements/popups/popup',
      phones: 'cabinet/advertisements/manage/getAdvertisementAssignedPhones',
    }),
    advertisementId() {
      return this.popup?.id || 0;
    },
  },
  methods: {
    ...mapActions({
      closePopup: 'cabinet/advertisements/popups/close',
      getAdvertisementAssignedPhones: 'cabinet/advertisements/manage/getAdvertisementAssignedPhones',
      getCallTrackingByAuto: 'cabinet/advertisements/manage/getCallTrackingByAuto',
      getCallTrackingByUser: 'cabinet/advertisements/manage/getCallTrackingByUser',
      fetchCallTrackingInfo: 'cabinet/advertisements/manage/fetchCallTrackingInfo',
    }),
    async getRepublicationInfo(advertisementId) {
      const params = { advertisementId };
      await this.getAdvertisementAssignedPhones(params)
        .then((data) => {
          if (!data?.length) this.closePopup();
        });
    },
    async getCallTrackingParams(autoId) {
      await this.getCallTrackingByUser();
      await this.getCallTrackingByAuto({ autoId })
        .then((data) => {
          this.callTrackingParams = data;
          this.currentStep = data.autoId ? 'governance' : 'manage';
        })
        .catch(() => {
          this.currentStep = 'manage';
        });
    },
    resetQueryParams() {
      this.$router.replace({ query: null });
    },
  },
  async mounted() {
    this.queryParams = this.$route.query;
    const { callTracking } = this.queryParams;
    this.showCallTrackingBlock = Boolean(callTracking);

    await this.getRepublicationInfo(this.advertisementId);
    await this.getCallTrackingParams(this.advertisementId);
    await this.fetchCallTrackingInfo();

    this.loading = false;
  },
};
