import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'RepublicationSettings',
	data() {
		return {
			active: 0,
			isEnableDefaultPreference: 0,
		};
	},
	methods: {
		...mapActions({
			openPopup: 'cabinet/advertisements/popups/open',
			fetchRepublicationInfo_: 'cabinet/advertisements/manage/getRepublicationInfo',
		}),
		fetchRepublicationInfo() {
			this.fetchRepublicationInfo_();
		}
	},
	computed: {
		...mapGetters({
			getGeneralRepublicationInfo_: 'cabinet/advertisements/manage/getGeneralRepublicationInfo',
		}),
		info() {
			return this.getGeneralRepublicationInfo_ || {};
		},
		republicationText() {
			let msg = '';
			const texts = this._result(this.info, 'messages.title', {});
			msg = this.active ? texts.enable : texts.disable;
			return msg;
		},
	},
	watch: {
		info(newValue) {
			this.active = newValue.active;
			this.isEnableDefaultPreference = newValue.isEnableDefaultPreference;
		},
	},
	mounted() {
		this.fetchRepublicationInfo();
	},
	i18n: require('./i18n').default,
};
