import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ExpireDate',
  computed: {
    ...mapGetters({
      getExpire: 'cabinet/advertisements/filter/expire'
    }),
    gte: {
      get() {
        return this.getExpire.gte;
      },
      set(value) {
        let expire = Object.assign({}, this.getExpire);
        if (value) {
          expire.gte = value;
        } else {
          delete expire.gte;
        }
        this.setExpire(expire);
      }
    },
    lte: {
      get() {
        return this.getExpire.lte;
      },
      set(value) {
        let expire = Object.assign({}, this.getExpire);
        if (value) {
          expire.lte = value;
        } else {
          delete expire.lte;
        }
        this.setExpire(expire);
      }
    }
  },
  methods: {
    ...mapActions({
      setExpire: 'cabinet/advertisements/filter/expire'
    })
  },
  i18n: {
    messages: {
      uk: {
        публикации: 'публікації',
        От: 'Від'
      },
      ru: {
        публикации: 'публикации',
        От: 'От'
      }
    }
  }
};
