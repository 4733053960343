export default {
	messages: {
		ru: {
			'Налаштування перепублікації': 'Настройка перепубликации',
			'Автоматична перепублікація': 'Автоматическая перепубликация',
			'вимкнена': 'выключена',
			'увімкнена': 'включена',
			'Налаштувати перепублікацію': 'Настроить перепубликацию',
		},
		uk: {
			'Налаштування перепублікації': 'Налаштування перепублікації',
			'Автоматична перепублікація': 'Автоматична перепублікація',
			'вимкнена': 'вимкнена',
			'увімкнена': 'увімкнена',
			'Налаштувати перепублікацію': 'Налаштувати перепублікацію'
		}
	}
};
