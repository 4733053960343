import {mapGetters} from 'vuex';
export default {
  name: 'Tabs',
  props: ['active'],
  computed: {
    ...mapGetters({
      counters: 'cabinet/leftBar/counters'
    }),
    active_() {
      if (this.counters && this.counters.MyAdvertisementsPublished) {
        return this.counters.MyAdvertisementsPublished.count;
      }
      return 0;
    },
    draft() {
      if (this.counters && this.counters.MyAdvertisementsDraft) {
        return this.counters.MyAdvertisementsDraft.count;
      }
      return 0;
    },
    autoBuy() {
      if (this.counters && this.counters.MyAdvertisementsAUTORIA24) {
        return this.counters.MyAdvertisementsAUTORIA24.count;
      }
      return 0;
    },
    archive() {
      if (this.counters && this.counters.MyAdvertisementsArchive) {
        return this.counters.MyAdvertisementsArchive.count;
      }
      return 0;
    },
    awaitForPayment() {
      if (this.counters && this.counters.MyAdvertisementsUnpayed) {
        return this.counters.MyAdvertisementsUnpayed.count;
      }
      return 0;
    }
  },
  i18n: {
    messages: {
      uk: {
        Активные: 'Активні',
        Черновики: 'Чернетки',
        'AUTO.RIA24': 'AUTO.RIA24',
        Архив: 'Архів',
        'Ожидают оплаты': 'Очікують оплати'
      },
      ru: {
        Активные: 'Активные',
        Черновики: 'Черновики',
        'AUTO.RIA24': 'AUTO.RIA24',
        Архив: 'Архив',
        'Ожидают оплаты': 'Ожидают оплаты'
      }
    }
  }
};
