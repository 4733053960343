import { mapActions, mapGetters } from 'vuex';
import i18n from '../i18n';

export default {
  name: 'ChangePhone',
  props: ['phones', 'params'],
  i18n,
  data() {
    return {
      visibleAlternateNumber: false,
    };
  },
  computed: {
    ...mapGetters({
      callTrackingByUser: 'cabinet/advertisements/manage/callTrackingByUser',
    }),
    isActive() {
      return Boolean(this.params?.useSubstitute);
    },
    substitutedPhone() {
      const substituted = this.phones?.find(({ id }) => id === this.params.phoneId);
      const defaultPhone = {
        real: { phoneFormatted: '' },
        substitute: { phoneFormatted: '' },
      };

      return substituted || defaultPhone;
    },
    daysDifference() {
      const startDate = new Date();
      const endDate = new Date(this.callTrackingByUser.expiredDate);
      const timeDifference = endDate - startDate;

      return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    },
  },
  mounted() {
    this.visibleAlternateNumber = this.isActive;
  },
  methods: {
    ...mapActions({
      switchCallTracking: 'cabinet/advertisements/manage/switchCallTracking',
    }),
    switchPhones() {
      this.visibleAlternateNumber = !this.visibleAlternateNumber;

      this.switchCallTracking({
        autoId: this.params.autoId,
        data: {
          useSubstitute: this.visibleAlternateNumber ? 1 : 0,
        },
      });
    },
  },
};
