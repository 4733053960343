import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Sort',
  computed: {
    ...mapGetters({
      getSort: 'cabinet/advertisements/filter/sort'
    }),
    selected: {
      set(value) {
        this.setSort(value);
      },
      get() {
        return this.getSort;
      }
    }
  },
  methods: {
    ...mapActions({
      setSort: 'cabinet/advertisements/filter/sort'
    })
  },
  i18n: {
    messages: {
      uk: {
        Сортировка: 'Сортування',
        Обычная: 'Звичайна',
        От: 'Від',
        Год: 'Рік',
        Пробег: 'Пробіг',
        по: 'за',
        выпуска: 'випуску',
        добавления: 'додавання',
        'дешевых к дорогим': 'дешевих до дорогих',
        'дорогих к дешевым': 'дорогих до дешевих',
        возрастанию: 'зростанням',
        убыванию: 'спаданням'
      },
      ru: {
        Сортировка: 'Сортировка',
        Обычная: 'Обычная',
        От: 'От',
        Год: 'Год',
        Пробег: 'Пробег',
        по: 'по',
        выпуска: 'выпуска',
        добавления: 'добавления',
        'дешевых к дорогим': 'дешевых к дорогим',
        'дорогих к дешевым': 'дорогих к дешевым',
        возрастанию: 'возрастанию',
        убыванию: 'убыванию'
      }
    }
  }
};
