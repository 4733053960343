import {mapActions, mapGetters} from 'vuex';
const { get } = require('../../../../helpers/cookie');

export default {
	data: function () {
		return {
			action: 0,
		};
	},
	model: {
		prop: 'checked',
		event: 'change'
	},
	props: {
		checked: Boolean,
		type: String,
		checks: Object,
		page: Number
	},
	computed: {
		...mapGetters({
			checked_: 'cabinet/advertisements/checked',
			userInfo: 'cabinet/userInfo/userData',
			langId: 'lang/id'
		})
	},
	methods: {
		slonik(body) {
			this._slonik(Object.assign({}, body, {
				project_id: 1,
				event_id: 352,
				screentype: this.isDesktop ? 1 : 2,
				owner_id: this.userInfo.id,
				version_type: 2,
				current_url: window.location.pathname,
				previous_url: document.referrer,
				lang_id: this.langId
			}));
		},
		...mapActions({
			openPopup: 'cabinet/advertisements/popups/open'
		}),
		apply() {
			let ids = Object.keys(this.checks).filter(id => this.checks[id]);
			if (!ids.length) {
				alert(this.$t('Сначала выберете объявление'));
				return;
			}
			switch (Number(this.action)) {
				case 1:
					this.slonik({click_action: 9, proposal_id: ids});
					this.$router.push({
						path: `${this.langPrefix}/cabinet/advertisements/manage`,
						query: {
							page: this.page,
							ipp: get('ipp') || 20,
							action: 'active',
							ids: ids.join(','),
						},
					});
				case 2:
					this.slonik({click_action: 11, proposal_id: ids});
					this.$router.push({
						path: `${this.langPrefix}/cabinet/advertisements/manage`,
						query: {
							page: this.page,
							ipp: get('ipp') || 20,
							action: 'active',
							ids: ids.join(','),
						},
					});
					break;
				case 4:
					this.slonik({click_action: 15, proposal_id: ids});
					this.$router.push({
						path: `${this.langPrefix}/cabinet/advertisements/manage`,
						query: {
							page: this.page,
							ipp: get('ipp') || 20,
							action: this.type,
							ids: ids.join(','),
						},
					});
					break;
				case 6:
					this.slonik({click_action: 17, proposal_id: ids});
					this.$router.push({
						path: `${this.langPrefix}/cabinet/advertisements/manage`,
						query: {
							page: this.page,
							ipp: get('ipp') || 20,
							action: 'archive',
							ids: ids.join(','),
						},
					});
					break;
				case 3:
					this.openPopup({name: 'MoveToArchivePopup', ids});
					break;
				case 5:
					this.openPopup({name: 'MoveToArchivePopup', ids});
					break;
				case 7:
					this.openPopup({name: 'DeletePopup', ids});
					break;
				case 8:
					this.openPopup({name: 'RepublicationPopup', ids});
					break;
				case 9:
					this.openPopup({name: 'SwitchPhone', ids});
					break;
			}
			this.action = 0;
		}
	},
	name: 'Actions',
	i18n: require('./i18n').default,
};
