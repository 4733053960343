import { mapGetters } from 'vuex';
import i18n from '../i18n';

export default {
  name: 'ChangePhone',
  props: ['phones', 'showService'],
  i18n,
  computed: {
    ...mapGetters({
      callTrackingDefaultInfo: 'cabinet/advertisements/manage/callTrackingDefaultInfo',
    }),
  },
};
